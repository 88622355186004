.report {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 812px)  {
  .mobileHidden {
    display: none;
  }
}

.report-body {
  flex-basis: 5;
}

.report-button {
  flex-basis: 1;
  width: 50%;
}

.report-textarea {
  width: 67%;
}

.flex-row {
  gap: 20px;
  flex-wrap: wrap;
}

.flex-col {
  align-items: center;
}

.titleAndInfo{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.infoCircle{
  display: block;
  margin: 0;
}

.linkToButton {
  padding: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  border-top: 2px solid #FFFFFF;
  border-right: 2px solid #FFFFFF;
  border-bottom: 2px solid #FFFFFF;
  border-left: 2px solid #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.csv-link {
  font: bold 12px Arial;
  text-decoration: none;
  background-color: #2b2a33;
  color: #333333;
}

.select-dropdown{
  width: 100%;
}

h3, h4 {
  margin: 15px;
}

.leech-info {
  /* color: #607d8b; */
  margin-bottom: 30px;
}
