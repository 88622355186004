.Upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    padding-bottom: 120px;
}

.file-div {
    background-color: #484354;
    display: block;
    justify-content: center;
    /* border-style: dotted; */
    border-color: black;
    border-width: medium;
    border-radius: 20px;
    margin: 20px;
    width: 100%;
}

.file-list {
    width: 75%;
    height: auto;
    text-align: left;
}

.upload-button {
    margin: 20px;
}

.upload-waiting{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ifwame {
    display: inherit;
}
.ifwame_mobile {
    display: none;
}

@media screen and (max-width: 812px)  {
    .ifwame {
        display: none;
    }
    .ifwame_mobile {
        display: inherit;
    }
}

a {
    color: #2196f3;
}
a:visited {
    color: #1769aa;
}
a:hover {
    color: #4dabf5;
}
a:active {
    color: #4dabf5;
}