/**____________________________________________________________
  * Header is a 100% width green bar across the top of the page\
  * There's a brick in the middle at 50% width that holds the title and the tokens/url|
  * For mobile, brick is just tokens/url. No title. Brick is now 100% top of screen/
  * ____________/////////////////////////_________________________________________/
  *             ///////////////////
  *             //////////////
  *             //////////
  *             ////
  */

.Header {
  position: sticky;
  top: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: #484354; /* BACKGROUND GREEN #484354 #1b5e20 */
}

.HeaderTitle {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

img {
  width: auto;
  height: 40px;
}

.HeaderNav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50%;
}

@media screen and (max-width: 812px) {
  .HeaderNav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.HeaderNav > * {
  flex: 1;
}

.outlined-basic {
  color: #1976d2;
}

