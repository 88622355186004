.product-page{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.product-container {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.product {
    height: 100%;
    width: 30%;
    background-color: #484354;
    border-style: solid;
    border-color: black;
    border-width: thin;
    border-radius: 20px;

    section {
        p {
            line-height: 28px;
        }
        button {
            border-style: none;
            background-color: bisque;
            color: #FFFFFF;
            size: 2em;
            padding: 0.5em 1em;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background-color: #efbe23;
            }
        }
    }
}

.product-spacer{
    width: 3.33%;
    height: 100%;
    float: left;
}


.product-img {
    /* display:block; */
    width: 55%;
    height: auto;
}

@media screen and (max-width: 812px) {
    .product {
        width: 100%;
    }
}

.inputAlign {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1em;
}

.inputAlign > div {
    width: 20px;
}

.inputAlign > input {
    width: 60px;
}

.productInput {
    width: 40px;
}

.purchaseButton {
    width: 117px;
}


