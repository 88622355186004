.login-wrapper {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
  height: 100%;
}

.login-title-and-logo {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  height: 33%;
  text-align: left;
}

.link-wrapper {
  display: grid;
  justify-content: center;
  padding: 1em;
}

.desktop_tutorial {
  display: inherit;
}
.mobile_tutorial {
  display: none;
}

.login-img > img{
  width: auto;
  height: 250px;
}

@media screen and (max-width: 812px) {
  .desktop_tutorial {
    display: none;
  }
  .mobile_tutorial {
    display: inherit;
  }

  .login-img > img{
    width: auto;
    height: 100px;
  }
}

h1 {
  align-self: center;
}

.login-block {
  background-color: #484354;
  border-radius: 10px;
  margin: 20px;
}

.login-register-wrapper{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.login-register-wrapper * {
  flex-grow: 1;
  padding: 20px;
}

h3, p {
  margin: 10px;
}

.login-form {
  padding-bottom: 1em;
}