.App {
  text-align: center;
  background-color: #212121;
  min-height: 100vh;
  height: 100%;
  font-size: 20px; /* Birfday soon :) */
  color: white;
  display: flex;
  flex-flow: column;
}

p {
  color: white;
}

/*
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #212121;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}
*/

.App-body {
  top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row > select {
  height: fit-content;
}

.flex-container > div {
  flex-basis: calc(50% - 20px);
  justify-content: center;
}