.product-page{
    width: 100%;
    height: 100%;
}

.CheckoutWrapper{
    width: 50%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin-bottom: 10px;

    p {
        line-height: 28px;
    }

}

@media screen and (max-width: 812px) {
    .CheckoutWrapper{
        width: 90%;
    }
}

.product-window {
    background-color: #484354;
    justify-content: center;
    border-style: solid;
    border-color: black;
    border-width: thin;
    margin: 20px;
    border-radius: 20px;
}

.ProductImage {
    width: 36%;
    height: auto;
}