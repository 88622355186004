.contact {
    background-color: #212121;
    color: white;
    height: auto;
    width: 100%;
    text-align: left;
    text-wrap: wrap;
    word-wrap: break-word;
}

.contact-align{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact-question {
    background-color: #424242;
    border-radius: 20px;
    /* color: #607d8b; */
    width: 80%;
    padding: 10px;
    text-align: center;
}

.subs-tier {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
}