.faq {
    background-color: #212121;
    color: white;
    height: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.faq-align {
    width: 85%;
    height: auto;
    text-wrap: wrap;
    word-wrap: break-word;
}

@media screen and (max-width: 812px) {
    .faq-align{
        width: 95%;
    }
  }

.faq-question {
    background-color: #424242;
    border-radius: 20px;
    /* color: #607d8b; */
    padding: 10px;
    text-align: center;
}
